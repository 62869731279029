/* eslint-disable no-unused-vars */
import React from 'react';
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody } from '../../components'
import { PageTitle } from '../../core';

import { useSelector } from 'react-redux';

function Profile() {
  const intl = useIntl()
  const { info } = useSelector(state => state.profile);

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.PROFILE.PROFILE' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <div className="d-flex flex-center flex-column py-5">
            <div className="symbol symbol-100px symbol-circle mb-7">
              <img src="/images/blank.png" alt="image" />
            </div>
            <div className="fs-3 text-gray-800 fw-bold mb-3">{info?.name}</div>
            <div className="fw-bold mb-3">
              { info?.email }
            </div>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export {Profile};
