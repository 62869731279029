import { handleResponse, newHeaders } from '../helpers';

const API_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

export const vehicleService = {
	getAll,
	getAllArchive,
	getId,
	create,
	update,
	_delete,
	archive,
	createExpense,
	updateExpense,
	deleteExpense
};

function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/list_vehicles`, requestOptions).then(handleResponse);
}

function getAllArchive() {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/list_vehicles_trashes`, requestOptions).then(handleResponse);
}

function getId(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/get_vehicle/${id}`, requestOptions).then(handleResponse);
}

function create(values) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_vehicle`, requestOptions).then(handleResponse);
}

function update(values, id) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_vehicle/${id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_vehicle_completely/${id}`, requestOptions).then(handleResponse);
}

function archive(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_vehicle/${id}`, requestOptions).then(handleResponse);
}

function createExpense(values) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_expense`, requestOptions).then(handleResponse);
}

function updateExpense(values, id) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_expense/${id}`, requestOptions).then(handleResponse);
}

function deleteExpense(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_expense/${id}`, requestOptions).then(handleResponse);
}