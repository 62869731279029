export function newHeaders() {
	const accessToken = localStorage.getItem('dehaCustomerToken');
	const bearer = `Bearer ${accessToken}`;

	return {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Authorization': bearer
	};
}
