import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { MetronicI18nProvider } from './i18n/Metronici18n'
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/sass/style.scss'
import './assets/sass/plugins.scss'
import './assets/sass/style.react.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </MetronicI18nProvider>
);

reportWebVitals();
