/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault, Footer, Header,} from '../components'
import { PageDataProvider } from '../core/PageData'
import clsx from 'clsx'

const MasterLayout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false)
  }, [location]);

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault opened={menuOpen}/>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <Header 
            onClick={(val) => setMenuOpen(val)} 
            isShow={menuOpen} 
          />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-fluid'>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>

        <div 
          className={clsx('drawer-overlay', {'d-flex': menuOpen})}
          onClick={() => setMenuOpen(false)} 
        />
      </div>
    </PageDataProvider>
  )
}

export {MasterLayout}
