/* eslint-disable */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {KTSVG, TransactionType} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTCard, KTCardBody, InputPrice, Select } from '../../'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import {useDispatch, useSelector} from 'react-redux'
import {personActions} from '../../../redux/actions'

const PaymentPlanModal = ({data, id, onClose}) => {
  const [show, setShow] = useState(false)
  const intl = useIntl()
  const {updatedTransaction} = useSelector((state) => state.person)

  const dispatch = useDispatch()

  const dateFormat = (date) => {
    const formattedDate = date.toLocaleDateString("tr-TR", {day: "2-digit", month: "2-digit", year: "numeric"}).replace(/\./g, "/");
    return formattedDate;
  }

  const validSchema = Yup.object().shape({
    vehicle_title: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    type: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    price: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    person_id: Number(id),
    price: 0,
    vehicle_title: '',
    type: '',
    description: '',
    trans_date: dateFormat(new Date()),
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(personActions.updateTransaction(values, data?.id))
    },
  })

  useEffect(() => {
    if (data) {
      setShow(true)
      formik.setFieldValue('person_id', id)
      formik.setFieldValue('price', data?.price)
      formik.setFieldValue('description', data?.description)
      formik.setFieldValue('vehicle_title', data?.vehicle_title)
      formik.setFieldValue('type', data?.type)
      const dateStr = "15/11/2024";
      const [day, month, year] = dateStr.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      formik.setFieldValue('trans_date', dateFormat(new Date(date)))
    }
  }, [data])

  useEffect(() => {
    if (updatedTransaction) setShow(false)
  }, [updatedTransaction])

  const handleDeleteTransaction = () => {
    dispatch(personActions.deleteTransaction(data?.id))
    setShow(false)
  }

  const onCloseModal = () => {
    setShow(false)
    onClose && onClose()
  }

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onCloseModal}
    >
      <div className='modal-content'>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
        <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'PERSON.TRANSACTION'})}</h5>
          <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={onCloseModal}
            >
              <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>
        <KTCard>
          <KTCardBody className='p-9'>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>        
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PERSON.TITLE' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    required
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('vehicle_title')}
                  />
                  {formik.touched.vehicle_title && formik.errors.vehicle_title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.vehicle_title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PERSON.TRANSDATE' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish, dateFormat: "d/m/Y" }}
                      {...formik.getFieldProps('trans_date')}
                      onChange={([date]) => formik.setFieldValue('trans_date', dateFormat(date))}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PERSON.TYPE' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <Select
                    data={TransactionType}
                    required
                    {...formik.getFieldProps('type')}
                    onChange={item => formik.setFieldValue('type', item?.value)}
                    textType={'intl'}
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PERSON.PRICE' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <InputPrice 
                    {...formik.getFieldProps('price')} 
                    onChange={(value) => formik.setFieldValue('price', Number(value))} 
                  />
                  {formik.touched.price && formik.errors.price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.price}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PERSON.DESCRIPTION' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <textarea
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    rows={2}
                    {...formik.getFieldProps('description')}
                  />
                </div>
              </div>
                      
              <div className='row pt-15'>
                <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </KTCardBody>
        </KTCard>

        <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
          <div className='d-flex align-items-center me-3'>
            <button className='btn btn-light me-3' onClick={onCloseModal}>
              {intl.formatMessage({id: 'BUTTON.CLOSE'})}
            </button>
            <button className='btn btn-primary' onClick={handleDeleteTransaction}>
              {intl.formatMessage({id: 'BUTTON.DELETE'})}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

PaymentPlanModal.propTypes = {
  id: PropTypes.number,
}

export {PaymentPlanModal}
