import { handleResponse, newHeaders } from '../helpers';

const API_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

export const personService = {
	getAll,
	getAllArchive,
	getId,
	create,
	update,
	_delete,
	archive,
	createTransaction,
	updateTransaction,
	deleteTransaction
};

function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/list_persons`, requestOptions).then(handleResponse);
}

function getAllArchive() {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/list_persons_trashes`, requestOptions).then(handleResponse);
}

function getId(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/get_person/${id}`, requestOptions).then(handleResponse);
}

function create(values) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_person`, requestOptions).then(handleResponse);
}

function update(values, id) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_person/${id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_person_completely/${id}`, requestOptions).then(handleResponse);
}

function archive(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_person/${id}`, requestOptions).then(handleResponse);
}

function createTransaction(values) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_transaction`, requestOptions).then(handleResponse);
}

function updateTransaction(values, id) {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders(),
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/set_transaction/${id}`, requestOptions).then(handleResponse);
}

function deleteTransaction(id) {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/delete_transaction/${id}`, requestOptions).then(handleResponse);
}