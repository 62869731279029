import { handleResponse, newHeaders } from '../helpers';

const API_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

export const profileService = {
	getProfile
};

function getProfile() {
	const requestOptions = {
		method: 'GET',
		headers: newHeaders(),
	};

	return fetch(`${API_URL}/user`, requestOptions).then(handleResponse);
}