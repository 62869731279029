/* eslint-disable react/jsx-no-target-blank */
import React, {Fragment} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import routes from '../../App/PrivateRoutes'
import {useSelector} from 'react-redux'
import {ListLoading} from '../../components/Table/ListLoading'

export function AsideMenuMain() {
  const intl = useIntl()
  const {info, loading} = useSelector((state) => state.profile)

  if (info)
    return (
      <>
        {loading && <ListLoading />}

        {!loading && (
          <>
            {routes?.map((route) => {
              if (route?.visibility == 'hidden') return <Fragment key={route.key} />

              if (route?.child) {
                return (
                  <AsideMenuItemWithSub
                    to={route.path}
                    title={intl.formatMessage({id: route.title})}
                    fontIcon='bi-archive'
                    icon={route?.icon}
                    key={route.key}
                  >
                    {route.child.map((children) => {
                      return (
                        <AsideMenuItem
                          key={children.key}
                          to={children.path}
                          title={intl.formatMessage({id: children.title})}
                          hasBullet={true}
                          className='ps-13'
                        />
                      )
                    })}
                  </AsideMenuItemWithSub>
                )
              }

              return (
                <AsideMenuItem
                  to={route.path}
                  icon={route.icon}
                  title={intl.formatMessage({id: route.title})}
                  fontIcon='bi-archive'
                  key={route.key}
                />
              )
            })}
          </>
        )}
      </>
    )

  return <></>
}
