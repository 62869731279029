/* eslint-disable */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, KTCardBody, InputPrice} from '../..'

import {useDispatch, useSelector} from 'react-redux'
import {vehicleActions} from '../../../redux/actions'

const SalesModal = ({data, onOpen, error, onClose}) => {
  const [show, setShow] = useState(onOpen);
  const intl = useIntl()
  const {isRedirect} = useSelector((state) => state.vehicle)

  const dispatch = useDispatch()

  const validSchema = Yup.object().shape({
    sales_price: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    title: '',
    price: 0,
    sales_price: 0,
    description: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(vehicleActions.update(values, data?.id))
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('title', data?.title)
      formik.setFieldValue('price', data?.price)
      formik.setFieldValue('sales_price', data?.sales_price)
      formik.setFieldValue('description', data?.description)
    }
  }, [data])

  useEffect(() => {
    if (isRedirect) {
      dispatch(vehicleActions?.archive(data?.id));
      setShow(false)
    }
  }, [isRedirect])

  useEffect(() => {
    setShow(onOpen)
  }, [onOpen])

  useEffect(() => {
    if (error && show) {
      setShow(false)
    }
  }, [error])

  const onCloseModal = () => {
    setShow(false)
    onClose && onClose()
  }

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onCloseModal}
    >
      <div className='modal-content'>
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'PERSON.TRANSACTION'})}</h5>
            <div className='d-flex ms-2'>
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={onCloseModal}
              >
                <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
          </div>
          <KTCard>
            <KTCardBody className='p-9'>
              <div className='row mb-6'>
                <label className='col-lg-5 required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'PERSON.PRICE'})}
                </label>
                <div className='col-lg-7 fv-row fv-plugins-icon-container'>
                  <InputPrice
                    {...formik.getFieldProps('sales_price')}
                    onChange={(value) => formik.setFieldValue('sales_price', Number(value))}
                  />
                  {formik.touched.sales_price && formik.errors.sales_price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sales_price}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </KTCardBody>
          </KTCard>

          <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <button className='btn btn-light me-3' onClick={onCloseModal}>
                {intl.formatMessage({id: 'BUTTON.CLOSE'})}
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT'})}</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'PLEASE'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

SalesModal.propTypes = {
  id: PropTypes.number,
}

export {SalesModal}
