import {authConstants} from '../constants'

const initialState = {
  loading: true,
  isRedirect: false,
  logout: false,
  error: null,
}

export function auth(state = {}, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {...state, ...initialState}
    case authConstants.LOGIN_SUCCESS:
      localStorage.setItem('dehaCustomerToken', action?.signin?.data?.token)

      return {
        ...state,
        isRedirect: action?.signin?.data?.token ? true : false,
        loading: false,
      }
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        isRedirect: false,
        error: action.error,
        loading: false,
      }

    case authConstants.LOGOUT_REQUEST:
      return {...state, ...initialState}
    case authConstants.LOGOUT_SUCCESS:
      localStorage.removeItem('dehaCustomerToken')

      return {
        ...state,
        logout: true,
        loading: false,
      }
    case authConstants.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    default:
      return state
  }
}
