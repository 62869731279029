/* eslint-disable react/react-in-jsx-scope */
import {useState} from 'react'
import PropTypes from 'prop-types'
import {ListToolbar} from './ListToolbar'

const ListHeader = ({buttons, actionButtons}) => {
  const [showFilter, setShowFilter] = useState(false)

  return (
    <div className='card-header pt-6 pb-6'>
      <div className='card-title'></div>
      <div className='card-toolbar'>
        <ListToolbar
          buttons={buttons}
          actionButtons={actionButtons}
          onClickShow={() => setShowFilter(!showFilter)}
        />
      </div>
    </div>
  )
}

ListHeader.propTypes = {
  buttons: PropTypes.array,
}

ListHeader.defaultProps = {
  buttons: [
    {
      title: 'BUTTON.ADD',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      to: 'create',
      variant: 'primary',
    },
  ],
}

export {ListHeader}
