import React, {Suspense} from 'react'
import {I18nProvider} from '../i18n/i18nProvider'

import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {history, isLogin} from '../helpers'
import routes from './PrivateRoutes'
import {AuthLayout} from './AuthLayout'
import {MasterLayout} from './MasterLayout'

import { useSelector } from 'react-redux';

import {Login} from '../pages/Auth'
import {Profile} from '../pages/Profile'
import {ProfileEdit} from '../pages/Profile/Edit'

import {ToastContainer} from 'react-toastify'
import {LayoutProvider, LayoutSplashScreen} from '../core'
import 'react-toastify/dist/ReactToastify.css'

import './App.scss'

const MainContent = () => {
  const { isRedirect } = useSelector(state => state.auth);
  const isAuthenticated = isRedirect || isLogin()
  const {PUBLIC_URL} = window.ENVVARS.REACT_APP_PUBLIC_URL;

  return (
    <div className='App d-flex flex-column flex-root'>
      <Router history={history} basename={PUBLIC_URL}>
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route element={<AuthLayout />}>
                <Route path='/login' element={<Login />} />
                <Route index element={<Login />} />
                <Route path='*' element={<Navigate to='/login' />} />
              </Route>
            </>
          ) : (
            <>
              <Route element={<MasterLayout />}>
                {routes.map((route) => {
                  if (route.child) {
                    return route.child.map((child) => {
                      if (child.pages) {
                        return child.pages.map((page) => (
                          <Route path={page.path} key={page.key} element={page.element} />
                        ))
                      }
                      return <Route path={child.path} key={child.key} element={child.element} />
                    })
                  }
                  return <Route path={route.path} key={route.key} element={route.element} />
                })}
                <Route path='/profile' element={<Profile />} />
                <Route path='/profile/edit' element={<ProfileEdit />} />
                <Route path='*' element={<Navigate to='/dashboard' />} />
              </Route>
            </>
          )}
        </Routes>
      </Router>
    </div>
  )
}

export default function App() {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <MainContent />
            <ToastContainer />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}
