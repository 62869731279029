/* eslint-disable react/react-in-jsx-scope */
import {useState, useEffect} from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { authActions } from '../../redux/actions';

function Login() {
  const intl = useIntl()
  const navigate = useNavigate();
  const { isRedirect, loading } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const validSchema = Yup.object().shape({
    email: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    password: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    email: '',
    password: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(authActions.login(values));
    },
  })

  useEffect(() => {
    if (isRedirect) navigate(`/dashboard`)
  }, [isRedirect])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Beneksus&apos;a Giriş Yapın</h1>
        <div className="text-gray-500 fw-semibold fs-6">Projeniz Kontrol Altında</div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export default Login;
