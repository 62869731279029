import {authConstants} from '../constants'
import {authService} from '../../services'

export const authActions = {
  login,
  logout
}

function login(values) {
  return (dispatch) => {
    dispatch(request(values))

    authService.login(values).then(
      (signin) => dispatch(success(signin)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values) {
    return {type: authConstants.LOGIN_REQUEST, values}
  }
  function success(signin) {
    return {type: authConstants.LOGIN_SUCCESS, signin}
  }
  function failure(error) {
    return {type: authConstants.LOGIN_FAILURE, error}
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request())

    authService.logout().then(
      (logout) => dispatch(success(logout)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: authConstants.LOGOUT_REQUEST}
  }
  function success(logout) {
    return {type: authConstants.LOGOUT_SUCCESS, logout}
  }
  function failure(error) {
    return {type: authConstants.LOGOUT_FAILURE, error}
  }
}
