export const vehicleConstants = {
  
  GETALL_VEHICLE_REQUEST: 'GETALL_VEHICLE_REQUEST',
  GETALL_VEHICLE_SUCCESS: 'GETALL_VEHICLE_SUCCESS',
  GETALL_VEHICLE_FAILURE: 'GETALL_VEHICLE_FAILURE',
  
  GETALL_ARCHIVE_VEHICLE_REQUEST: 'GETALL_ARCHIVE_VEHICLE_REQUEST',
  GETALL_ARCHIVE_VEHICLE_SUCCESS: 'GETALL_ARCHIVE_VEHICLE_SUCCESS',
  GETALL_ARCHIVE_VEHICLE_FAILURE: 'GETALL_ARCHIVE_VEHICLE_FAILURE',
  
  GETID_VEHICLE_REQUEST: 'GETID_VEHICLE_REQUEST',
  GETID_VEHICLE_SUCCESS: 'GETID_VEHICLE_SUCCESS',
  GETID_VEHICLE_FAILURE: 'GETALL_VEHICLE_FAILURE',
  
  CREATE_VEHICLE_REQUEST: 'CREATE_VEHICLE_REQUEST',
  CREATE_VEHICLE_SUCCESS: 'CREATE_VEHICLE_SUCCESS',
  CREATE_VEHICLE_FAILURE: 'CREATE_VEHICLE_FAILURE',
  
  UPDATE_VEHICLE_REQUEST: 'UPDATE_VEHICLE_REQUEST',
  UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_FAILURE: 'UPDATE_VEHICLE_FAILURE',
  
  ARCHIVE_VEHICLE_REQUEST: 'ARCHIVE_VEHICLE_REQUEST',
  ARCHIVE_VEHICLE_SUCCESS: 'ARCHIVE_VEHICLE_SUCCESS',
  ARCHIVE_VEHICLE_FAILURE: 'ARCHIVE_VEHICLE_FAILURE',
  
  DELETE_VEHICLE_REQUEST: 'DELETE_VEHICLE_REQUEST',
  DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILURE: 'DELETE_VEHICLE_FAILURE',

  CREATE_EXPENSE_VEHICLE_REQUEST: 'CREATE_EXPENSE_VEHICLE_REQUEST',
  CREATE_EXPENSE_VEHICLE_SUCCESS: 'CREATE_EXPENSE_VEHICLE_SUCCESS',
  CREATE_EXPENSE_VEHICLE_FAILURE: 'CREATE_EXPENSE_VEHICLE_FAILURE',

  UPDATE_EXPENSE_VEHICLE_REQUEST: 'UPDATE_EXPENSE_VEHICLE_REQUEST',
  UPDATE_EXPENSE_VEHICLE_SUCCESS: 'UPDATE_EXPENSE_VEHICLE_SUCCESS',
  UPDATE_EXPENSE_VEHICLE_FAILURE: 'UPDATE_EXPENSE_VEHICLE_FAILURE',
  
  DELETE_EXPENSE_VEHICLE_REQUEST: 'DELETE_EXPENSE_VEHICLE_REQUEST',
  DELETE_EXPENSE_VEHICLE_SUCCESS: 'DELETE_EXPENSE_VEHICLE_SUCCESS',
  DELETE_EXPENSE_VEHICLE_FAILURE: 'DELETE_EXPENSE_VEHICLE_FAILURE',
  
};
