import { handleResponse, newHeaders } from '../helpers';

const API_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

export const authService = {
	login,
	logout
};

function login(values) {
	const requestOptions = {
		method: 'POST',
		headers: {
      'Content-Type': 'application/json'
    },
		body: JSON.stringify(values)
	};

	return fetch(`${API_URL}/login`, requestOptions).then(handleResponse);
}

function logout() {
	const requestOptions = {
		method: 'POST',
		headers: newHeaders()
	};

	return fetch(`${API_URL}/logout`, requestOptions).then(handleResponse);
}