/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG } from '../../../helpers'
import clsx from 'clsx';

import { KTCard, KTCardBody, KTCardHeader, DeleteModal, DataTables } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { personActions } from '../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { person, isDeleted, error, loading } = useSelector(state => state.person);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')


  useEffect(() => {
    dispatch(personActions?.getId(id));
  }, [id])

  const handleDelete = () => dispatch(personActions?._delete(id));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.ARCHIVE' }),
      path: '/person/archive',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ARCHIVE.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>

          {person && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{person.name_surname}</div>

                            <div className='ms-5'>
                            </div>
                          </div>

                          <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {person?.created_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-info'
                              />
                              {intl.formatDate(new Date(person?.created_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                          {person?.deleted_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin005.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-success'
                              />
                              {intl.formatDate(new Date(person?.deleted_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PERSON.TOTAL' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(person?.total, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
          
            {person?.transactions?.length > 0 && <KTCard>
              <KTCardHeader title={intl.formatMessage({id: 'VEHICLE.EXPENSE' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />
              <KTCardBody className='p-9' collapseble isShow={isCollapse}>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>
                          {intl.formatMessage({id: 'PERSON.TRANSDATE'})}
                        </th>
                        <th className='min-w-140px'>
                          {intl.formatMessage({id: 'PERSON.TITLE'})}
                        </th>
                        <th className='min-w-140px'>
                          {intl.formatMessage({id: 'PERSON.DESCRIPTION'})}
                        </th>
                        <th className='min-w-140px'>
                          {intl.formatMessage({id: 'PERSON.TYPE'})}
                        </th>
                        <th className='min-w-140px'>
                          {intl.formatMessage({id: 'PERSON.PRICE'})}
                        </th>
                        <th className='min-w-140px'>
                          {intl.formatMessage({id: 'PERSON.TOTAL'})}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {person?.transactions?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={clsx({
                              'table-info': item?.type == 'sermaye',
                              'table-success': item?.type == 'kar',
                              'table-danger': item?.type == 'cikis',
                            })}
                          >
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {item.trans_date}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {item.vehicle_title}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {item.description}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {item.type}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>
                                {intl.formatNumber(item?.price, {
                                  style: 'currency',
                                  currency: 'try',
                                })}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>
                                {intl.formatNumber(item?.total, {
                                  style: 'currency',
                                  currency: 'try',
                                })}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </KTCardBody>
            </KTCard> }
          </>}
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
