import {personConstants} from '../constants'
import {personService} from '../../services'

export const personActions = {
  getAll,
  getAllArchive,
  getId,
  create,
  update,
  _delete,
  archive,
  createTransaction,
  updateTransaction,
  deleteTransaction
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    personService.getAll().then(
      (persons) => dispatch(success(persons)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: personConstants.GETALL_PERSON_REQUEST}
  }
  function success(persons) {
    return {type: personConstants.GETALL_PERSON_SUCCESS, persons}
  }
  function failure(error) {
    return {type: personConstants.GETALL_PERSON_FAILURE, error}
  }
}

function getAllArchive() {
  return (dispatch) => {
    dispatch(request())

    personService.getAllArchive().then(
      (archives) => dispatch(success(archives)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: personConstants.GETALL_ARCHIVE_PERSON_REQUEST}
  }
  function success(archives) {
    return {type: personConstants.GETALL_ARCHIVE_PERSON_SUCCESS, archives}
  }
  function failure(error) {
    return {type: personConstants.GETALL_ARCHIVE_PERSON_FAILURE, error}
  }
}

function getId(id) {
  return (dispatch) => {
    dispatch(request(id))

    personService.getId(id).then(
      (person) => dispatch(success(person)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: personConstants.GETID_PERSON_REQUEST}
  }
  function success(person) {
    return {type: personConstants.GETID_PERSON_SUCCESS, person}
  }
  function failure(error) {
    return {type: personConstants.GETID_PERSON_FAILURE, error}
  }
}

function create(values) {
  return (dispatch) => {
    dispatch(request(values))

    personService.create(values).then(
      (create) => dispatch(success(create)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values) {
    return {type: personConstants.CREATE_PERSON_REQUEST, values}
  }
  function success(create) {
    return {type: personConstants.CREATE_PERSON_SUCCESS, create}
  }
  function failure(error) {
    return {type: personConstants.CREATE_PERSON_FAILURE, error}
  }
}

function update(values, id) {
  return (dispatch) => {
    dispatch(request(values, id))

    personService.update(values, id).then(
      (update) => dispatch(success(update)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values, id) {
    return {type: personConstants.UPDATE_PERSON_REQUEST, values, id}
  }
  function success(update) {
    return {type: personConstants.UPDATE_PERSON_SUCCESS, update}
  }
  function failure(error) {
    return {type: personConstants.UPDATE_PERSON_FAILURE, error}
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request(id))

    personService._delete(id).then(
      (deleted) => dispatch(success(deleted)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: personConstants.DELETE_PERSON_REQUEST, id}
  }
  function success(deleted) {
    return {type: personConstants.DELETE_PERSON_SUCCESS, deleted}
  }
  function failure(error) {
    return {type: personConstants.DELETE_PERSON_FAILURE, error}
  }
}

function archive(id) {
  return (dispatch) => {
    dispatch(request(id))

    personService.archive(id).then(
      (archive) => dispatch(success(archive)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: personConstants.ARCHIVE_PERSON_REQUEST, id}
  }
  function success(archive) {
    return {type: personConstants.ARCHIVE_PERSON_SUCCESS, archive}
  }
  function failure(error) {
    return {type: personConstants.ARCHIVE_PERSON_FAILURE, error}
  }
}

function createTransaction(values) {
  return (dispatch) => {
    dispatch(request(values))

    personService.createTransaction(values).then(
      (createTransaction) => dispatch(success(createTransaction)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values) {
    return {type: personConstants.CREATE_TRANSACTION_PERSON_REQUEST, values}
  }
  function success(createTransaction) {
    return {type: personConstants.CREATE_TRANSACTION_PERSON_SUCCESS, createTransaction}
  }
  function failure(error) {
    return {type: personConstants.CREATE_TRANSACTION_PERSON_FAILURE, error}
  }
}

function updateTransaction(values, id) {
  return (dispatch) => {
    dispatch(request(values, id))

    personService.updateTransaction(values, id).then(
      (updatedTransaction) => dispatch(success(updatedTransaction)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values, id) {
    return {type: personConstants.UPDATE_TRANSACTION_PERSON_REQUEST, values, id}
  }
  function success(updatedTransaction) {
    return {type: personConstants.UPDATE_TRANSACTION_PERSON_SUCCESS, updatedTransaction}
  }
  function failure(error) {
    return {type: personConstants.UPDATE_TRANSACTION_PERSON_FAILURE, error}
  }
}

function deleteTransaction(id) {
  return (dispatch) => {
    dispatch(request(id))

    personService.deleteTransaction(id).then(
      (deletedTransaction) => dispatch(success(deletedTransaction)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: personConstants.DELETE_TRANSACTION_PERSON_REQUEST, id}
  }
  function success(deletedTransaction) {
    return {type: personConstants.DELETE_TRANSACTION_PERSON_SUCCESS, deletedTransaction}
  }
  function failure(error) {
    return {type: personConstants.DELETE_TRANSACTION_PERSON_FAILURE, error}
  }
}