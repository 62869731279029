import { vehicleConstants } from '../constants';

const initialState = {
  loading: true, 
  error: null,
  isRedirect: false,
  isDeleted: false,
  isArchived: false,
  createExpense: false,
  updatedExpense: false,
  deletedExpense: false,
}

export function vehicle(state = {}, action) {
  switch (action.type) {
 
    case vehicleConstants.GETALL_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.GETALL_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action?.vehicles?.data?.vehicles,
        loading: false
      };
    case vehicleConstants.GETALL_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
 
    case vehicleConstants.GETALL_ARCHIVE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.GETALL_ARCHIVE_VEHICLE_SUCCESS:
      return {
        ...state,
        archives: action?.archives?.data?.vehicles,
        loading: false
      };
    case vehicleConstants.GETALL_ARCHIVE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case vehicleConstants.GETID_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.GETID_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: action?.vehicle?.data?.vehicle,
        loading: false
      };
    case vehicleConstants.GETID_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.CREATE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isRedirect: action?.create?.success,
        loading: false
      };
    case vehicleConstants.CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.UPDATE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isRedirect: action?.update?.success,
        loading: false
      };
    case vehicleConstants.UPDATE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.DELETE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        isDeleted: action?.deleted?.success,
        loading: false
      };
    case vehicleConstants.DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.ARCHIVE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.ARCHIVE_VEHICLE_SUCCESS:
      return {
        ...state,
        isArchived: action?.archive?.success,
        loading: false
      };
    case vehicleConstants.ARCHIVE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case vehicleConstants.CREATE_EXPENSE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.CREATE_EXPENSE_VEHICLE_SUCCESS:
      return {
        ...state,
        createExpense: action?.createExpense?.success,
        loading: false
      };
    case vehicleConstants.CREATE_EXPENSE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.UPDATE_EXPENSE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.UPDATE_EXPENSE_VEHICLE_SUCCESS:
      return {
        ...state,
        updatedExpense: action?.updatedExpense?.success,
        loading: false
      };
    case vehicleConstants.UPDATE_EXPENSE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case vehicleConstants.DELETE_EXPENSE_VEHICLE_REQUEST:
      return { ...state, ...initialState };
    case vehicleConstants.DELETE_EXPENSE_VEHICLE_SUCCESS:
      console.log("action", action.deletedExpense)
      return {
        ...state,
        deletedExpense: action?.deletedExpense?.success,
        loading: false
      };
    case vehicleConstants.DELETE_EXPENSE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    default:
      return state
  }
}
