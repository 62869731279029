import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { profile } from './profile.reducer';
import { vehicle } from './vehicle.reducer';
import { person } from './person.reducer';

const rootReducer = combineReducers({
  auth,
  profile,
  vehicle,
  person
});

export default rootReducer;
