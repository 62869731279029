/* eslint-disable */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTCard, KTCardBody, InputPrice } from '../../'

import {useDispatch, useSelector} from 'react-redux'
import {vehicleActions} from '../../../redux/actions'

const CheckoutDetail = ({data, onClose}) => {
  const [show, setShow] = useState(false)
  const intl = useIntl()
  const {updatedExpense} = useSelector((state) => state.vehicle)

  const dispatch = useDispatch()

  const validSchema = Yup.object().shape({
    description: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
    price: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    price: data?.price,
    description: data?.description,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(vehicleActions.updateExpense(values, data?.id))
    },
  })

  useEffect(() => {
    if (data) {
      setShow(true)
      formik.setFieldValue('price', data?.price)
      formik.setFieldValue('description', data?.description)
    }
  }, [data])

  useEffect(() => {
    if (updatedExpense) setShow(false)
  }, [updatedExpense])

  const handleDeleteExpense = () => {
    dispatch(vehicleActions.deleteExpense(data?.id))
    setShow(false)
  }

  const onCloseModal = () => {
    setShow(false)
    onClose && onClose()
  }

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onCloseModal}
    >
      <div className='modal-content'>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
        <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'VEHICLE.EXPENSE'})}</h5>
          <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={onCloseModal}
            >
              <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>
        <KTCard>
          <KTCardBody className='p-9'>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>        
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'VEHICLE.DESCRIPTION' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    type='text'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'VEHICLE.PRICE' })}</label>
                <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                  <InputPrice 
                    {...formik.getFieldProps('price')} 
                    onChange={(value) => formik.setFieldValue('price', Number(value))} 
                  />
                  {formik.touched.price && formik.errors.price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.price}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
               
              <div className='row'>
                <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex justify-content-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </KTCardBody>
        </KTCard>

        <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
          <div className='d-flex align-items-center me-3'>
            <button className='btn btn-light me-3' onClick={onCloseModal}>
              {intl.formatMessage({id: 'BUTTON.CLOSE'})}
            </button>
            <button className='btn btn-primary' onClick={handleDeleteExpense}>
              {intl.formatMessage({id: 'BUTTON.DELETE'})}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CheckoutDetail.propTypes = {
  id: PropTypes.number,
}

export {CheckoutDetail}
