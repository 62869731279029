import React, {useMemo}  from 'react'
import clsx from 'clsx'
import {useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import { useIntl } from 'react-intl'

const Table = ({items, cols, paddless}) => {
  const intl = useIntl()
  const data = useMemo(() => items, [items])
  const columns = useMemo(() => cols, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className={clsx('table align-middle table-row-dashed gy-5 fs-6 dataTable no-footer table-striped table-hover', {'table-flush': paddless})}
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 gs-0'>
              {headers.map((column) => <CustomHeaderColumn key={column.id} column={column} />)}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, i) => {
              prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`} paddless={paddless} />
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  {intl.formatMessage({ id: 'NON_RESULT' })}
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {Table};