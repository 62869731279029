import {profileConstants} from '../constants'
import {profileService} from '../../services'

export const profileActions = {
  getProfile,
}

function getProfile() {
  return (dispatch) => {
    dispatch(request())

    profileService.getProfile().then(
      (profile) => dispatch(success(profile)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: profileConstants.GETPROFILE_REQUEST}
  }
  function success(profile) {
    return {type: profileConstants.GETPROFILE_SUCCESS, profile}
  }
  function failure(error) {
    return {type: profileConstants.GETPROFILE_FAILURE, error}
  }
}
