/* eslint-disable react/react-in-jsx-scope */
import {Dashboard} from '../pages/Dashboard'
import {VehicleList, VehicleDetail, VehicleCreate, VehicleEdit, ArchiveList, ArchiveDetail} from '../pages/Vehicle'
import {PersonList, PersonDetail, PersonCreate, PersonEdit, PersonArchiveList, PersonArchiveDetail} from '../pages/Person'

const routes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    key: 'dashboard',
    title: 'MENU.DASHBOARD',
    exact: true,
    icon: '/images/icons/nav/Dashboard.svg',
  },
  {
    path: '/vehicle/vehicle',
    key: 'vehicle',
    title: 'MENU.VEHICLE',
    icon: '/images/icons/duotune/general/gen002.svg',
    child: [
      {
        path: '/vehicle/vehicle',
        element: <VehicleList />,
        key: 'vehicle-list',
        title: 'MENU.VEHICLE',
        pages: [
          {
            path: '/vehicle/vehicle',
            element: <VehicleList />,
            key: 'vehicle-list-page',
          },
          {
            path: '/vehicle/vehicle/detail/:id',
            element: <VehicleDetail />,
            key: 'vehicle-detail-page',
          },
          {
            path: '/vehicle/vehicle/edit/:id',
            element: <VehicleEdit />,
            key: 'vehicle-edit-page',
          },
          {
            path: '/vehicle/vehicle/create',
            element: <VehicleCreate />,
            key: 'vehicle-create-page',
          },
        ],
      },
      {
        path: '/vehicle/sales',
        element: <ArchiveList />,
        key: 'sales-list',
        title: 'MENU.VEHICLE.SALES',
        pages: [
          {
            path: '/vehicle/sales',
            element: <ArchiveList />,
            key: 'sales-list-page',
          },
          {
            path: '/vehicle/sales/detail/:id',
            element: <ArchiveDetail />,
            key: 'sales-detail-page',
          }
        ],
      },
    ],
  },
  {
    path: '/person/person',
    key: 'person',
    title: 'MENU.PERSON',
    icon: '/images/icons/duotune/finance/fin010.svg',
    child: [
      {
        path: '/person/person',
        element: <PersonList />,
        key: 'person-list',
        title: 'MENU.PERSON',
        pages: [
          {
            path: '/person/person',
            element: <PersonList />,
            key: 'person-list-page',
          },
          {
            path: '/person/person/detail/:id',
            element: <PersonDetail />,
            key: 'person-detail-page',
          },
          {
            path: '/person/person/edit/:id',
            element: <PersonEdit />,
            key: 'person-edit-page',
          },
          {
            path: '/person/person/create',
            element: <PersonCreate />,
            key: 'person-create-page',
          },
        ],
      },
      {
        path: '/person/archive',
        element: <PersonArchiveList />,
        key: 'archive-list',
        title: 'MENU.ARCHIVE',
        pages: [
          {
            path: '/person/archive',
            element: <PersonArchiveList />,
            key: 'archive-list-page',
          },
          {
            path: '/person/archive/detail/:id',
            element: <PersonArchiveDetail />,
            key: 'archive-detail-page',
          }
        ],
      },
    ],
  },
]

export default routes
