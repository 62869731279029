/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG } from '../../../helpers'

import { KTCard, KTCardBody, KTCardHeader, DeleteModal, DataTables } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleActions } from '../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { vehicle, isDeleted, error, loading } = useSelector(state => state.vehicle);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')


  useEffect(() => {
    dispatch(vehicleActions?.getId(id));
  }, [id])

  const handleDelete = () => dispatch(vehicleActions?._delete(id));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SALES' }),
      path: '/vehicle/sales',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SALES.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>

          {vehicle && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{vehicle.title}</div>

                          <div className='ms-5'>
                          </div>
                          </div>

                          <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {vehicle?.created_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-info'
                              />
                              {intl.formatDate(new Date(vehicle?.created_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                          {vehicle?.deleted_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin005.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-success'
                              />
                              {intl.formatDate(new Date(vehicle?.deleted_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.PRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.price, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.COSTSSUM' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.costs_sum, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.TOTALCOST' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.total_cost, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.SALESPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.sales_price, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.TOTALGAIN' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.total_gain, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {vehicle?.description && <div className='notice d-flex rounded border-primary border border-dashed p-6'>
                  {vehicle?.description}
                </div>}
              </KTCardBody>
            </KTCard>
          
            {vehicle?.expenses?.length > 0 && <KTCard>
              <KTCardHeader title={intl.formatMessage({id: 'VEHICLE.EXPENSE' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />
              <KTCardBody className='p-9' collapseble isShow={isCollapse}>
                <DataTables
                  columns={[
                    {
                      title: intl.formatMessage({ id: 'VEHICLE.DESCRIPTION' }),
                      prop: 'description',
                      isSortable: true,
                      isFilterable: true
                    },
                    {
                      title: intl.formatMessage({ id: 'VEHICLE.EXPENSE.PRICE' }),
                      prop: 'price',
                      cell: (row) => intl.formatNumber(row.price, { style: 'currency', currency: 'try' }),
                      isSortable: true,
                      isFilterable: true,
                      alignment: {
                        horizontal: 'right'
                      }
                    }]
                  }
                  data={vehicle?.expenses}
                />
              </KTCardBody>
            </KTCard> }
          </>}
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
