import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../core'
import {useDispatch, useSelector} from 'react-redux'
import {personActions, vehicleActions} from '../../redux/actions'
import {ChartColumn} from '../../components'
import {KTSVG} from '../../helpers'

function Dashboard() {
  const intl = useIntl()
  const {info, loading} = useSelector((state) => state.profile)
  const {persons} = useSelector((state) => state.person)
  const {vehicles, archives} = useSelector((state) => state.vehicle)
  const dispatch = useDispatch()

  const [total, setTotal] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [totalGain, setTotalGain] = useState(0)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    dispatch(personActions.getAll())
    dispatch(personActions.getAllArchive())
    dispatch(vehicleActions.getAll())
    dispatch(vehicleActions.getAllArchive())
  }, [])

  useEffect(() => {
    if (persons) {
      const totalAmount = persons.reduce((accumulator, person) => accumulator + person.total, 0)
      setTotal(totalAmount)
    }
  }, [persons])

  useEffect(() => {
    if (vehicles) {
      const totalCost = vehicles.reduce((accumulator, person) => accumulator + person.total_cost, 0)
      setTotalCost(totalCost)
    }
  }, [vehicles])

  useEffect(() => {
    if (archives) {
      const totalGain = archives.reduce((accumulator, person) => accumulator + person.total_gain, 0)
      setTotalGain(totalGain)

      const deletedVehicles = archives.filter((vehicle) => vehicle.deleted_at !== null)

      const monthlyData = deletedVehicles.reduce((acc, vehicle) => {
        const date = new Date(vehicle.deleted_at)
        const month = new Intl.DateTimeFormat('tr', {year: 'numeric', month: '2-digit'}).format(
          date
        )

        if (!acc[month]) {
          acc[month] = 0
        }
        acc[month] += vehicle.total_gain
        return acc
      }, {})

      const formattedData = Object.keys(monthlyData).map((month) => ({
        x: month,
        y: monthlyData[month],
      }))

      setChartData(formattedData)
    }
  }, [archives])

  console.log(chartData)
  return (
    <>
      <PageTitle>
        {intl.formatMessage({id: 'DASHBOARD.HELLO'})}
        {info?.name}
      </PageTitle>

      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-success`}>
                <div className='d-flex text-center flex-column text-white pt-8'>
                  <span className='fw-semibold fs-7'>
                    {intl.formatMessage({id: 'DASHBOARD.TOTAL.CAPITAL'})}
                  </span>
                  <span className='fw-bold fs-2x pt-1'>
                    {intl.formatNumber(total, {style: 'currency', currency: 'try'})}
                  </span>
                </div>
              </div>
              <div
                className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                style={{marginTop: '-100px'}}
              >
                <div className='d-flex align-items-center mb-6'>
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/images/icons/duotune/finance/fin008.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <div className='fs-5 text-gray-800 fw-bold'>
                        {intl.formatMessage({id: 'DASHBOARD.TOTAL.COST'})}
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>
                        {intl.formatNumber(totalCost, {style: 'currency', currency: 'try'})}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/images/icons/duotune/finance/fin005.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <div className='fs-5 text-gray-800 fw-bold'>
                        {intl.formatMessage({id: 'DASHBOARD.TOTAL.GAIN'})}
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>
                        {intl.formatNumber(totalGain, {style: 'currency', currency: 'try'})}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-primary`}>
                <div className='d-flex text-center flex-column text-white pt-8'>
                  <span className='fw-semibold fs-7'>
                    {intl.formatMessage({id: 'DASHBOARD.VEHICLE.TOTAL'})}
                  </span>
                  <span className='fw-bold fs-2x pt-1'>{vehicles?.length}</span>
                </div>
              </div>
              <div
                className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                style={{marginTop: '-100px'}}
              >
                <div className='d-flex align-items-center mb-6'>
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/images/icons/duotune/coding/cod007.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <div className='fs-5 text-gray-800 fw-bold'>
                        {intl.formatMessage({id: 'DASHBOARD.PERSON.TOTAL'})}
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{persons?.length}</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/images/icons/duotune/general/gen002.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <div className='fs-5 text-gray-800 fw-bold'>
                        {intl.formatMessage({id: 'DASHBOARD.VEHICLE.SALESTOTAL'})}
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{archives?.length}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        {chartData?.length > 0 && (
          <div className='col-xl-12 mb-10'>
            <ChartColumn
              chartHeight='400px'
              data={chartData}
              categories={chartData?.map((item) => `${item?.x}`)}
              title={intl.formatMessage({id: 'DASHBOARD.SALES.MONTHLY'})}
              tooltip={{
                y: {
                  formatter: (val) => {
                    return intl.formatNumber(val, {style: 'currency', currency: 'try'})
                  },
                },
              }}
            />
          </div>
        )}

        {archives?.length > 0 && (
          <div className='col-xl-12 mb-10'>
            <ChartColumn
              chartHeight='400px'
              categories={archives?.map((item) => `${item?.title}`)}
              data={archives?.map((item) => item?.total_gain)}
              title={intl.formatMessage({id: 'DASHBOARD.VEHICLE.TOTALGAIN'})}
              tooltip={{
                y: {
                  formatter: (val) => {
                    return intl.formatNumber(val, {style: 'currency', currency: 'try'})
                  },
                },
              }}
            />
          </div>
        )}

        {persons?.length > 0 && (
          <div className='col-xl-12 mb-10'>
            <ChartColumn
              chartHeight='400px'
              categories={persons?.map((item) => `${item?.name_surname}`)}
              data={persons?.map((item) => item.total)}
              title={intl.formatMessage({id: 'DASHBOARD.PERSON.PRICE'})}
              legend={false}
              dataLabels
              tooltip={{
                y: {
                  formatter: (val) => {
                    return intl.formatNumber(val, {style: 'currency', currency: 'try'})
                  },
                },
              }}
            />
          </div>
        )}
      </div>

      {loading && (
        <div className='page-loader flex-column bg-dark bg-opacity-50'>
          <span className='spinner-border text-primary' role='status'></span>
          <span className='text-light fs-6 fw-semibold mt-5'>
            {intl.formatMessage({id: 'LOADING'})}
          </span>
        </div>
      )}
    </>
  )
}
export {Dashboard}
