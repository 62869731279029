import { profileConstants } from '../constants';

const initialState = {
  loading: true, 
  error: null,
}

export function profile(state = {}, action) {
  switch (action.type) {
 
    case profileConstants.GETPROFILE_REQUEST:
      return { ...state, ...initialState };
    case profileConstants.GETPROFILE_SUCCESS:
      return {
        ...state,
        info: action.profile,
        loading: false
      };
    case profileConstants.GETPROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    
    default:
      return state
  }
}
