import {vehicleConstants} from '../constants'
import {vehicleService} from '../../services'

export const vehicleActions = {
  getAll,
  getAllArchive,
  getId,
  create,
  update,
  _delete,
  archive,
  createExpense,
  updateExpense,
  deleteExpense
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    vehicleService.getAll().then(
      (vehicles) => dispatch(success(vehicles)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: vehicleConstants.GETALL_VEHICLE_REQUEST}
  }
  function success(vehicles) {
    return {type: vehicleConstants.GETALL_VEHICLE_SUCCESS, vehicles}
  }
  function failure(error) {
    return {type: vehicleConstants.GETALL_VEHICLE_FAILURE, error}
  }
}

function getAllArchive() {
  return (dispatch) => {
    dispatch(request())

    vehicleService.getAllArchive().then(
      (archives) => dispatch(success(archives)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: vehicleConstants.GETALL_ARCHIVE_VEHICLE_REQUEST}
  }
  function success(archives) {
    return {type: vehicleConstants.GETALL_ARCHIVE_VEHICLE_SUCCESS, archives}
  }
  function failure(error) {
    return {type: vehicleConstants.GETALL_ARCHIVE_VEHICLE_FAILURE, error}
  }
}

function getId(id) {
  return (dispatch) => {
    dispatch(request(id))

    vehicleService.getId(id).then(
      (vehicle) => dispatch(success(vehicle)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return {type: vehicleConstants.GETID_VEHICLE_REQUEST}
  }
  function success(vehicle) {
    return {type: vehicleConstants.GETID_VEHICLE_SUCCESS, vehicle}
  }
  function failure(error) {
    return {type: vehicleConstants.GETID_VEHICLE_FAILURE, error}
  }
}

function create(values) {
  return (dispatch) => {
    dispatch(request(values))

    vehicleService.create(values).then(
      (create) => dispatch(success(create)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values) {
    return {type: vehicleConstants.CREATE_VEHICLE_REQUEST, values}
  }
  function success(create) {
    return {type: vehicleConstants.CREATE_VEHICLE_SUCCESS, create}
  }
  function failure(error) {
    return {type: vehicleConstants.CREATE_VEHICLE_FAILURE, error}
  }
}

function update(values, id) {
  return (dispatch) => {
    dispatch(request(values, id))

    vehicleService.update(values, id).then(
      (update) => dispatch(success(update)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values, id) {
    return {type: vehicleConstants.UPDATE_VEHICLE_REQUEST, values, id}
  }
  function success(update) {
    return {type: vehicleConstants.UPDATE_VEHICLE_SUCCESS, update}
  }
  function failure(error) {
    return {type: vehicleConstants.UPDATE_VEHICLE_FAILURE, error}
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request(id))

    vehicleService._delete(id).then(
      (deleted) => dispatch(success(deleted)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: vehicleConstants.DELETE_VEHICLE_REQUEST, id}
  }
  function success(deleted) {
    return {type: vehicleConstants.DELETE_VEHICLE_SUCCESS, deleted}
  }
  function failure(error) {
    return {type: vehicleConstants.DELETE_VEHICLE_FAILURE, error}
  }
}

function archive(id) {
  return (dispatch) => {
    dispatch(request(id))

    vehicleService.archive(id).then(
      (archive) => dispatch(success(archive)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: vehicleConstants.ARCHIVE_VEHICLE_REQUEST, id}
  }
  function success(archive) {
    return {type: vehicleConstants.ARCHIVE_VEHICLE_SUCCESS, archive}
  }
  function failure(error) {
    return {type: vehicleConstants.ARCHIVE_VEHICLE_FAILURE, error}
  }
}

function createExpense(values) {
  return (dispatch) => {
    dispatch(request(values))

    vehicleService.createExpense(values).then(
      (createExpense) => dispatch(success(createExpense)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values) {
    return {type: vehicleConstants.CREATE_EXPENSE_VEHICLE_REQUEST, values}
  }
  function success(createExpense) {
    return {type: vehicleConstants.CREATE_EXPENSE_VEHICLE_SUCCESS, createExpense}
  }
  function failure(error) {
    return {type: vehicleConstants.CREATE_EXPENSE_VEHICLE_FAILURE, error}
  }
}

function updateExpense(values, id) {
  return (dispatch) => {
    dispatch(request(values, id))

    vehicleService.updateExpense(values, id).then(
      (updatedExpense) => dispatch(success(updatedExpense)),
      (error) => dispatch(failure(error))
    )
  }

  function request(values, id) {
    return {type: vehicleConstants.UPDATE_EXPENSE_VEHICLE_REQUEST, values, id}
  }
  function success(updatedExpense) {
    return {type: vehicleConstants.UPDATE_EXPENSE_VEHICLE_SUCCESS, updatedExpense}
  }
  function failure(error) {
    return {type: vehicleConstants.UPDATE_EXPENSE_VEHICLE_FAILURE, error}
  }
}

function deleteExpense(id) {
  return (dispatch) => {
    dispatch(request(id))

    vehicleService.deleteExpense(id).then(
      (deletedExpense) => dispatch(success(deletedExpense)),
      (error) => dispatch(failure(error))
    )
  }

  function request(id) {
    return {type: vehicleConstants.DELETE_EXPENSE_VEHICLE_REQUEST, id}
  }
  function success(deletedExpense) {
    return {type: vehicleConstants.DELETE_EXPENSE_VEHICLE_SUCCESS, deletedExpense}
  }
  function failure(error) {
    return {type: vehicleConstants.DELETE_EXPENSE_VEHICLE_FAILURE, error}
  }
}