/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG } from '../../../helpers'

import { KTCard, KTCardBody, InputPrice, DeleteModal, SalesModal, DataTables, CheckoutDetail, KTCardHeader } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleActions } from '../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';

import * as Yup from 'yup'
import { useFormik } from 'formik'

function Detail() {
  const [show, setShow] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [expense, setExpense] = useState();
  const [isCollapse, setIsCollapse] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { vehicle, isDeleted, isArchived, error, loading, createExpense, updatedExpense, deletedExpense } = useSelector(state => state.vehicle);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    description: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    price: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    vehicle_id: Number(id),
    price: 0,
    description: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(vehicleActions?.createExpense(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(vehicleActions?.getId(id));
  }, [id, createExpense, updatedExpense, deletedExpense])

  const handleDelete = () => dispatch(vehicleActions?._delete(id));

  const handleSales = () => dispatch(vehicleActions?.archive(id));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.VEHICLE' }),
      path: '/vehicle/vehicle',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.VEHICLE.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>

          {vehicle && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{vehicle.title}</div>

                          <div className='ms-5'>
                          </div>
                          </div>

                          <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {vehicle?.created_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-info'
                              />
                              {intl.formatDate(new Date(vehicle?.created_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                          {vehicle?.deleted_at && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin005.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1 text-success'
                              />
                              {intl.formatDate(new Date(vehicle?.deleted_at), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <button onClick={() => setShowSales(true)} className="btn btn-sm btn-success">
                          <KTSVG
                            path='/images/icons/duotune/finance/fin005.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.SALES' })}
                        </button>

                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item href={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.EDIT' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.PRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.price, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.COSTSSUM' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.costs_sum, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.TOTALCOST' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.total_cost, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.SALESPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.sales_price, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'VEHICLE.TOTALGAIN' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(vehicle.total_gain, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {vehicle?.description && <div className='notice d-flex rounded border-primary border border-dashed p-6'>
                  {vehicle?.description}
                </div>}
              </KTCardBody>
            </KTCard>
          
            <KTCard>
              <KTCardHeader title={intl.formatMessage({id: 'VEHICLE.EXPENSE' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />
              <KTCardBody className='p-9' collapseble isShow={isCollapse}>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>        
                  <div className='row mb-6'>
                    <label className='col-lg-2 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'VEHICLE.DESCRIPTION' })}</label>
                    <div className='col-lg-3 fv-row fv-plugins-icon-container'>
                      <input
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        type='text'
                        {...formik.getFieldProps('description')}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.description}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <label className='col-lg-2 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'VEHICLE.PRICE' })}</label>
                    <div className='col-lg-3 fv-row fv-plugins-icon-container'>
                      <InputPrice 
                        {...formik.getFieldProps('price')} 
                        onChange={(value) => formik.setFieldValue('price', Number(value))} 
                      />
                      {formik.touched.price && formik.errors.price && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.price}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-2'>
                      <button
                        type='submit'
                        className='btn btn-primary w-100'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>

                {vehicle?.expenses?.length > 0 && <>
                  <div className="separator"></div>
                  <DataTables
                    columns={[
                      {
                        title: intl.formatMessage({ id: 'VEHICLE.DESCRIPTION' }),
                        prop: 'description',
                        isSortable: true,
                        isFilterable: true
                      },
                      {
                        title: intl.formatMessage({ id: 'VEHICLE.EXPENSE.PRICE' }),
                        prop: 'price',
                        cell: (row) => intl.formatNumber(row.price, { style: 'currency', currency: 'try' }),
                        isSortable: true,
                        isFilterable: true,
                        alignment: {
                          horizontal: 'right'
                        }
                      }]
                    }
                    data={vehicle?.expenses}
                    onClick={(item) => setExpense(item)}
                  />
                </> }
              </KTCardBody>
            </KTCard>
          </>}
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <SalesModal
        onClick={handleSales}
        onOpen={showSales}
        onClose={() => setShowSales(false)}
        error={error}
        data={vehicle}
      />

      <CheckoutDetail data={expense} onClose={() => setExpense(null)} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
