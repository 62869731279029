import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { vehicleActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { vehicles, loading } = useSelector(state => state.vehicle);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(vehicleActions.getAll());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'VEHICLE.TITLE' })} className='min-w-125px' />,
      accessor: 'title',
      Cell: ({ ...props }) => <CustomCell item={props}  />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'VEHICLE.DESCRIPTION' })} className='min-w-125px' />,
      accessor: 'description',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'VEHICLE.PRICE' })} className='min-w-125px' />,
      accessor: 'price',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'VEHICLE.TOTALCOST' })} className='min-w-125px' />,
      accessor: 'total_cost',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'VEHICLE.UPDATED' })} className='min-w-125px' />,
      accessor: 'updated_at',
      Cell: ({ ...props }) => <CustomCell item={props} date="long" />,
    }
  ];


  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.VEHICLE' })}</PageTitle>
      <KTCard>
        <ListHeader />
        <KTCardBody>
          {!vehicles && <ListLoading />}
          {vehicles && <Table
            items={vehicles}
            cols={Columns}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
