export const TransactionType = [
  {
    id: 'kar',
    name: 'TYPE.PROFIT'
  },
  {
    id: 'cikis',
    name: 'TYPE.OUTPUT'
  },
  {
    id: 'sermaye',
    name: 'TYPE.CAPITAL'
  }
]