import { personConstants } from '../constants';

const initialState = {
  loading: true, 
  error: null,
  isRedirect: false,
  isDeleted: false,
  isArchived: false,
  createTransaction: false,
  updatedTransaction: false,
  deletedTransaction: false,
}

export function person(state = {}, action) {
  switch (action.type) {
 
    case personConstants.GETALL_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.GETALL_PERSON_SUCCESS:
      return {
        ...state,
        persons: action?.persons?.data?.persons,
        loading: false
      };
    case personConstants.GETALL_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
 
    case personConstants.GETALL_ARCHIVE_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.GETALL_ARCHIVE_PERSON_SUCCESS:
      return {
        ...state,
        archives: action?.archives?.data?.persons,
        loading: false
      };
    case personConstants.GETALL_ARCHIVE_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case personConstants.GETID_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.GETID_PERSON_SUCCESS:
      return {
        ...state,
        person: action?.person?.data?.person,
        loading: false
      };
    case personConstants.GETID_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.CREATE_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.CREATE_PERSON_SUCCESS:
      return {
        ...state,
        isRedirect: action?.create?.success,
        loading: false
      };
    case personConstants.CREATE_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.UPDATE_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        isRedirect: action?.update?.success,
        loading: false
      };
    case personConstants.UPDATE_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.DELETE_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.DELETE_PERSON_SUCCESS:
      return {
        ...state,
        isDeleted: action?.deleted?.success,
        loading: false
      };
    case personConstants.DELETE_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.ARCHIVE_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.ARCHIVE_PERSON_SUCCESS:
      return {
        ...state,
        isArchived: action?.archive?.success,
        loading: false
      };
    case personConstants.ARCHIVE_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case personConstants.CREATE_TRANSACTION_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.CREATE_TRANSACTION_PERSON_SUCCESS:
      return {
        ...state,
        createTransaction: action?.createTransaction?.success,
        loading: false
      };
    case personConstants.CREATE_TRANSACTION_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.UPDATE_TRANSACTION_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.UPDATE_TRANSACTION_PERSON_SUCCESS:
      return {
        ...state,
        updatedTransaction: action?.updatedTransaction?.success,
        loading: false
      };
    case personConstants.UPDATE_TRANSACTION_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case personConstants.DELETE_TRANSACTION_PERSON_REQUEST:
      return { ...state, ...initialState };
    case personConstants.DELETE_TRANSACTION_PERSON_SUCCESS:
      console.log("action", action.deletedTransaction)
      return {
        ...state,
        deletedTransaction: action?.deletedTransaction?.success,
        loading: false
      };
    case personConstants.DELETE_TRANSACTION_PERSON_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    default:
      return state
  }
}
