export function handleResponse(response) {
	return response.text().then((text) => {
		let data;
		try {
			data = JSON.parse(text);
		} catch (error) {
			data = text
		}

		if (!response.ok) {
			if (response.status === 401) {
				window.location.reload(true);
			}

			const error = (data && data?.message) || response?.statusText || data;
			return Promise.reject(error);
		}

		return data;
	});
}
