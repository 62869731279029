/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../core'
import {KTSVG, TransactionType} from '../../../helpers'

import {
  KTCard,
  KTCardBody,
  InputPrice,
  Select,
  DeleteModal,
  ArchiveModal,
  DataTables,
  PaymentPlanModal,
  KTCardHeader,
} from '../../../components'
import {useDispatch, useSelector} from 'react-redux'
import {personActions} from '../../../redux/actions'

import Dropdown from 'react-bootstrap/Dropdown'

import * as Yup from 'yup'
import {useFormik} from 'formik'

import Flatpickr from 'react-flatpickr'
import {Turkish} from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/flatpickr.css'
import clsx from 'clsx'

function Detail() {
  const [show, setShow] = useState(false)
  const [showArchive, setShowArchive] = useState(false)
  const [transaction, setTransaction] = useState()
  const [isCollapse, setIsCollapse] = useState(false)
  const [isCollapseTransaction, setIsCollapseTransaction] = useState(true)

  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const {
    person,
    isDeleted,
    isArchived,
    error,
    loading,
    createTransaction,
    updatedTransaction,
    deletedTransaction,
  } = useSelector((state) => state.person)

  const dispatch = useDispatch()

  const pageUrl = location.pathname.split('/')

  const dateFormat = (date) => {
    const formattedDate = date
      .toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'})
      .replace(/\./g, '/')
    return formattedDate
  }

  const validSchema = Yup.object().shape({
    vehicle_title: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
    type: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
    price: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    person_id: Number(id),
    price: 0,
    vehicle_title: '',
    type: '',
    description: '',
    trans_date: dateFormat(new Date()),
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(personActions?.createTransaction(values))
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(personActions?.getId(id))
  }, [id, createTransaction, updatedTransaction, deletedTransaction])

  const handleDelete = () => dispatch(personActions?._delete(id))

  const handleArchive = () => dispatch(personActions?.archive(id))

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'BC.PERSON'}),
      path: '/person/person',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>
        {intl.formatMessage({id: 'BC.PERSON.DETAIL'})}
      </PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          {person && (
            <>
              <KTCard className='mb-5'>
                <KTCardBody className='p-9'>
                  <div className='d-flex flex-wrap'>
                    <div className='flex-grow-1 w-100'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap row'>
                        <div className='d-flex flex-column col-xl-7'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='text-gray-900 fs-2 fw-bold me-1'>
                              {person.name_surname}
                            </div>

                            <div className='ms-5'></div>
                          </div>

                          <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                            {person?.created_at && (
                              <>
                                <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                                  <KTSVG
                                    path='/images/icons/duotune/general/gen014.svg'
                                    className='svg-icon svg-icon-2 fs-4 me-1 text-info'
                                  />
                                  {intl.formatDate(new Date(person?.created_at), {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </div>
                              </>
                            )}

                            {person?.deleted_at && (
                              <>
                                <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                                  <KTSVG
                                    path='/images/icons/duotune/finance/fin005.svg'
                                    className='svg-icon svg-icon-2 fs-4 me-1 text-success'
                                  />
                                  {intl.formatDate(new Date(person?.deleted_at), {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                          <Link
                            to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`}
                            className='btn btn-sm btn-primary align-self-center'
                          >
                            <KTSVG
                              path='/images/icons/duotune/general/gen055.svg'
                              className='svg-icon-2'
                            />
                            {intl.formatMessage({id: 'BUTTON.EDIT'})}
                          </Link>

                          <Dropdown autoClose='outside' align={{lg: 'end'}}>
                            <Dropdown.Toggle
                              variant='secondary'
                              size='sm'
                              className='iconless ps-3 pe-3'
                            >
                              <KTSVG
                                path='/images/icons/duotune/general/gen053.svg'
                                className='svg-icon-2'
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3'>
                              <Dropdown.Item
                                onClick={() => setShowArchive(true)}
                                className='menu-item px-3'
                              >
                                <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                                  {intl.formatMessage({id: 'BUTTON.ARCHIVE'})}
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setShow(true)}
                                className='menu-item px-3'
                              >
                                <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                                  {intl.formatMessage({id: 'BUTTON.DELETE'})}
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    {person?.total && (
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                          <div className='d-flex flex-wrap'>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                              <div className='fw-semibold fs-6 text-gray-400'>
                                {intl.formatMessage({id: 'PERSON.TOTAL'})}
                              </div>
                              <div className='fw-bold fs-4'>
                                {intl.formatNumber(person?.total, {
                                  style: 'currency',
                                  currency: 'try',
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </KTCardBody>
              </KTCard>

              <KTCard>
                <KTCardHeader
                  title={intl.formatMessage({id: 'PERSON.TRANSACTION.FORM'})}
                  collapsed={isCollapse}
                  onCollapse={(event) => setIsCollapse(event)}
                />
                <KTCardBody className='p-9' collapseble isShow={isCollapse}>
                  <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>
                        {intl.formatMessage({id: 'PERSON.TITLE'})}
                      </label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          required
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('vehicle_title')}
                        />
                        {formik.touched.vehicle_title && formik.errors.vehicle_title && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.vehicle_title}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>
                        {intl.formatMessage({id: 'PERSON.TRANSDATE'})}
                      </label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <div className='position-relative d-flex align-items-center'>
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className='form-control form-control-solid ps-12'
                            options={{locale: Turkish, dateFormat: 'd/m/Y'}}
                            {...formik.getFieldProps('trans_date')}
                            onChange={([date]) =>
                              formik.setFieldValue('trans_date', dateFormat(date))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>
                        {intl.formatMessage({id: 'PERSON.TYPE'})}
                      </label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <Select
                          data={TransactionType}
                          required
                          {...formik.getFieldProps('type')}
                          onChange={(item) => formik.setFieldValue('type', item?.value)}
                          textType={'intl'}
                        />
                        {formik.touched.type && formik.errors.type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.type}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PERSON.PRICE'})}
                      </label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <InputPrice
                          {...formik.getFieldProps('price')}
                          onChange={(value) => formik.setFieldValue('price', Number(value))}
                        />
                        {formik.touched.price && formik.errors.price && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.price}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PERSON.DESCRIPTION'})}
                      </label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <textarea
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('description')}
                        />
                      </div>
                    </div>

                    <div className='row pt-15'>
                      <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                          disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                          <span className='indicator-label'>
                            {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                          </span>
                          {formik.isSubmitting && (
                            <span className='indicator-progress'>
                              {intl.formatMessage({id: 'PLEASE'})}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </KTCardBody>
              </KTCard>

              <KTCard className='mt-5'>
                <KTCardHeader
                  title={intl.formatMessage({id: 'PERSON.TRANSACTION'})}
                  collapsed={isCollapseTransaction}
                  onCollapse={(event) => setIsCollapseTransaction(event)}
                />
                <KTCardBody className='p-9' collapseble isShow={isCollapseTransaction}>
                  {person?.transactions?.length > 0 && (
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>
                              {intl.formatMessage({id: 'PERSON.TRANSDATE'})}
                            </th>
                            <th className='min-w-140px'>
                              {intl.formatMessage({id: 'PERSON.TITLE'})}
                            </th>
                            <th className='min-w-140px'>
                              {intl.formatMessage({id: 'PERSON.DESCRIPTION'})}
                            </th>
                            <th className='min-w-140px'>
                              {intl.formatMessage({id: 'PERSON.TYPE'})}
                            </th>
                            <th className='min-w-140px'>
                              {intl.formatMessage({id: 'PERSON.PRICE'})}
                            </th>
                            <th className='min-w-140px'>
                              {intl.formatMessage({id: 'PERSON.TOTAL'})}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {person?.transactions?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={(item) => setTransaction(item)}
                                className={clsx({
                                  'table-info': item?.type == 'sermaye',
                                  'table-success': item?.type == 'kar',
                                  'table-danger': item?.type == 'cikis',
                                })}
                              >
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item.trans_date}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item.vehicle_title}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item.description}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item.type}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatNumber(item?.price, {
                                      style: 'currency',
                                      currency: 'try',
                                    })}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatNumber(item?.total, {
                                      style: 'currency',
                                      currency: 'try',
                                    })}
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </KTCardBody>
              </KTCard>
            </>
          )}
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <PaymentPlanModal data={transaction} id={Number(id)} onClose={() => setTransaction(null)} />

      {loading && (
        <div className='page-loader flex-column bg-dark bg-opacity-50'>
          <span className='spinner-border text-primary' role='status'></span>
          <span className='text-light fs-6 fw-semibold mt-5'>
            {intl.formatMessage({id: 'LOADING'})}
          </span>
        </div>
      )}
    </>
  )
}
export {Detail}
