import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { personActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { persons, loading } = useSelector(state => state.person);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(personActions.getAll());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'PERSON.NAME' })} className='min-w-125px' />,
      accessor: 'name_surname',
      Cell: ({ ...props }) => <CustomCell item={props}  />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'PERSON.TOTAL' })} className='min-w-125px' />,
      accessor: 'total',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'PERSON.UPDATED' })} className='min-w-125px' />,
      accessor: 'updated_at',
      Cell: ({ ...props }) => <CustomCell item={props} date="long" />,
    }
  ];


  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.PERSON' })}</PageTitle>
      <KTCard>
        <ListHeader />
        <KTCardBody>
          {!persons && <ListLoading />}
          {persons && <Table
            items={persons}
            cols={Columns}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
