export const personConstants = {
  
  GETALL_PERSON_REQUEST: 'GETALL_PERSON_REQUEST',
  GETALL_PERSON_SUCCESS: 'GETALL_PERSON_SUCCESS',
  GETALL_PERSON_FAILURE: 'GETALL_PERSON_FAILURE',
  
  GETALL_ARCHIVE_PERSON_REQUEST: 'GETALL_ARCHIVE_PERSON_REQUEST',
  GETALL_ARCHIVE_PERSON_SUCCESS: 'GETALL_ARCHIVE_PERSON_SUCCESS',
  GETALL_ARCHIVE_PERSON_FAILURE: 'GETALL_ARCHIVE_PERSON_FAILURE',
  
  GETID_PERSON_REQUEST: 'GETID_PERSON_REQUEST',
  GETID_PERSON_SUCCESS: 'GETID_PERSON_SUCCESS',
  GETID_PERSON_FAILURE: 'GETALL_PERSON_FAILURE',
  
  CREATE_PERSON_REQUEST: 'CREATE_PERSON_REQUEST',
  CREATE_PERSON_SUCCESS: 'CREATE_PERSON_SUCCESS',
  CREATE_PERSON_FAILURE: 'CREATE_PERSON_FAILURE',
  
  UPDATE_PERSON_REQUEST: 'UPDATE_PERSON_REQUEST',
  UPDATE_PERSON_SUCCESS: 'UPDATE_PERSON_SUCCESS',
  UPDATE_PERSON_FAILURE: 'UPDATE_PERSON_FAILURE',
  
  ARCHIVE_PERSON_REQUEST: 'ARCHIVE_PERSON_REQUEST',
  ARCHIVE_PERSON_SUCCESS: 'ARCHIVE_PERSON_SUCCESS',
  ARCHIVE_PERSON_FAILURE: 'ARCHIVE_PERSON_FAILURE',
  
  DELETE_PERSON_REQUEST: 'DELETE_PERSON_REQUEST',
  DELETE_PERSON_SUCCESS: 'DELETE_PERSON_SUCCESS',
  DELETE_PERSON_FAILURE: 'DELETE_PERSON_FAILURE',

  CREATE_TRANSACTION_PERSON_REQUEST: 'CREATE_TRANSACTION_PERSON_REQUEST',
  CREATE_TRANSACTION_PERSON_SUCCESS: 'CREATE_TRANSACTION_PERSON_SUCCESS',
  CREATE_TRANSACTION_PERSON_FAILURE: 'CREATE_TRANSACTION_PERSON_FAILURE',

  UPDATE_TRANSACTION_PERSON_REQUEST: 'UPDATE_TRANSACTION_PERSON_REQUEST',
  UPDATE_TRANSACTION_PERSON_SUCCESS: 'UPDATE_TRANSACTION_PERSON_SUCCESS',
  UPDATE_TRANSACTION_PERSON_FAILURE: 'UPDATE_TRANSACTION_PERSON_FAILURE',
  
  DELETE_TRANSACTION_PERSON_REQUEST: 'DELETE_TRANSACTION_PERSON_REQUEST',
  DELETE_TRANSACTION_PERSON_SUCCESS: 'DELETE_TRANSACTION_PERSON_SUCCESS',
  DELETE_TRANSACTION_PERSON_FAILURE: 'DELETE_TRANSACTION_PERSON_FAILURE',
  
};
